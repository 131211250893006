import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class MediaType extends IppyDippyCrudModel {
  protected static _typeLabel = "Media Type";
  protected static _typeLabelPlural = "Media Types";
  protected static asProperty = "media_type";
  public static api = {
    path: "media-types",
    fullCache: true,
  };
  protected static routeBase = "media-types";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = [];

  protected static propertyDefinitions = [
    {
      type: "NumberProperty",
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "label",
        label: "Label",
        rules: ["required"],
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        name: "contents",
        label: "Content",
        relatedModel: "Content",
        foreignProperty: "media_type",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: "NumberField", opts: { property: "id" } },
    {
      type: "DateTimeField",
      opts: { property: "created_at" },
    },
    { type: "DateTimeField", opts: { property: "updated_at" } },
    { type: "DateTimeField", opts: { property: "deleted_at" } },
    {
      type: "StringField",
      opts: { property: "label", isPrimaryLabelField: true, cols: 12 },
    },
    {
      type: "RelationshipFieldMany",
      opts: {
        property: "contents",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        cols: 12,
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["label", "contents"],
        },
        {
          id: "1",
          fields: ["created_at", "updated_at"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["label"],
      enableSearch: true,
      useModal: true,
      enableDynamicFilters: true,
      dynamicFilterOptions: {
        filterFields: ["label", "content"],
      },
      defaultSort: [
        {
          field: "label",
          order: "asc",
        },
      ],
    },
  ];
}
