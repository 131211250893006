import { CrudField } from "./CrudField";
import { CrudModel } from "./CrudModel";

export enum UserRole {
  SuperAdmin = "SuperAdmin",
  SuperUser = "SuperUser",
  EntityOwner = "EntityOwner",
  NonManager = "NonManager",
  LoggedIn = "LoggedIn",
  Guest = "Guest",
}
export enum UserPermission {
  Edit = 3,
  New = 2,
  EditOnly = 1.5,
  Read = 1,
  Hidden = 0,
}
export interface PermissableEntity {
  getUserId(): number | null;
  id: number | null;
  isNew: boolean;
}
export type IUserPermission =
  | UserPermission
  | ((permissableEntity: PermissableEntity) => UserPermission);

export interface IUserPermissions {
  [UserRole.SuperAdmin]?: IUserPermission;
  [UserRole.SuperUser]?: IUserPermission;
  [UserRole.EntityOwner]?: IUserPermission;
  [UserRole.NonManager]?: IUserPermission;
  [UserRole.LoggedIn]?: IUserPermission;
  [UserRole.Guest]?: IUserPermission;
}
export class UserPermissions {
  [UserRole.SuperAdmin]?: IUserPermission;
  [UserRole.SuperUser]?: IUserPermission;
  [UserRole.EntityOwner]?: IUserPermission;
  [UserRole.NonManager]?: IUserPermission;
  [UserRole.LoggedIn]?: IUserPermission;
  [UserRole.Guest]?: IUserPermission;

  protected get _classRef(): typeof UserPermissions {
    return Object.getPrototypeOf(this).constructor;
  }
  protected static $nuxt;
  protected get $nuxt() {
    return this._classRef.$nuxt;
  }
  public static setNuxtContext(context) {
    this.$nuxt = context;
  }

  private _opts: IUserPermissions | IUserPermission;
  constructor(
    opts: IUserPermissions | IUserPermission,
    fallbackPermissionArg?: IUserPermission
  ) {
    this._opts = opts;

    const fallbackPermission = fallbackPermissionArg
      ? fallbackPermissionArg
      : typeof opts === "number"
      ? opts
      : false;
    if (fallbackPermission) {
      // it's a single UserPermission, apply to all roles
      this[UserRole.SuperAdmin] = fallbackPermission;
      this[UserRole.SuperUser] = fallbackPermission;
      this[UserRole.EntityOwner] = fallbackPermission;
      this[UserRole.NonManager] = fallbackPermission;
      this[UserRole.LoggedIn] = fallbackPermission;
      this[UserRole.Guest] = fallbackPermission;
    }
    if (typeof opts !== "number") {
      Object.keys(opts).forEach((key) => {
        if (typeof opts[key] !== "undefined") this[key] = opts[key];
      });
    }
  }

  private resolveRolePermission(
    role: UserRole,
    model?: PermissableEntity,
    field?: CrudField
  ) {
    if (typeof this[role] === "undefined") return UserPermission.Hidden;

    if (typeof this[role] === "function")
      return (this[role] as Function)(model, field);

    return this[role];
  }

  private getUserPermission(
    model?: CrudModel,
    field?: CrudField
  ): UserPermission {
    // collect all applicable roles & their permissions
    const roles: {
      role: UserRole;
      permission: UserPermission;
    }[] = [];

    // not logged in?
    if (!this.$nuxt.$auth.loggedIn) {
      return this.resolveRolePermission(UserRole.Guest, model, field);
    } else {
      roles.push({
        role: UserRole.LoggedIn,
        permission: this.resolveRolePermission(UserRole.LoggedIn, model, field),
      });
    }

    // admin?
    if (this.$nuxt.$auth.user.is_admin)
      roles.push({
        role: UserRole.SuperAdmin,
        permission: this.resolveRolePermission(
          UserRole.SuperAdmin,
          model,
          field
        ),
      });

    // super user?
    if (this.$nuxt.$auth.user.is_super_user)
      roles.push({
        role: UserRole.SuperUser,
        permission: this.resolveRolePermission(
          UserRole.SuperUser,
          model,
          field
        ),
      });

    // owner?
    if (model) {
      const entityUserId = model.getUserId();
      const curUserIsOwner = Array.isArray(entityUserId)
        ? entityUserId.includes(this.$nuxt.$auth.user.id)
        : entityUserId == this.$nuxt.$auth.user.id;

      if (curUserIsOwner)
        roles.push({
          role: UserRole.EntityOwner,
          permission: this.resolveRolePermission(
            UserRole.EntityOwner,
            model,
            field
          ),
        });
    }

    // find the role with the highest permission
    const mostPriveledgedRole = roles.reduce((a, b) => {
      if (typeof this[a.role] === "undefined") return b;
      if (typeof this[b.role] === "undefined") return a;

      return a.permission > b.permission ? a : b;
    });

    return mostPriveledgedRole.permission;
  }

  public isVisibleToUser(model?: CrudModel, field?: CrudField) {
    return this.getUserPermission(model, field) !== UserPermission.Hidden;
  }

  public userCanCreateNew(model?: CrudModel, field?: CrudField) {
    const permission = this.getUserPermission(model, field);
    return (
      permission == UserPermission.Edit ||
      (model && permission == UserPermission.New && model.isNew)
    );
  }

  public isReadonlyToUser(model?: CrudModel, field?: CrudField): boolean {
    const permission = this.getUserPermission(model, field);

    if (model && model.id == 3349) console.log("isReadonlyToUser", permission);

    return !!(
      permission == UserPermission.Read ||
      (model && permission == UserPermission.New && !model.isNew)
    );
  }
}

export interface HasUserPermissions {
  isReadonlyToUser(user?, model?: PermissableEntity): boolean;
  isVisibleToUser(user?, model?: PermissableEntity): boolean;
}
