import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class QuizAnswer extends IppyDippyCrudModel {
  protected static _typeLabel = "Answer";
  protected static _typeLabelPlural = "Answers";
  protected static asProperty = "quiz_answer";
  public static api = {
    path: "quiz-answers",
  };
  protected static routeBase = "quiz-answers";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected userIdField = [];

  protected static propertyDefinitions = [
    {
      type: "NumberProperty",
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "answer",
        label: "Answer",
        rules: ["required"],
        maxLength: 255,
      },
    },
    {
      type: "NumberProperty",
      opts: {
        name: "weight",
        label: "Weight",
        default: 0,
        rules: ["required"],
        description:
          "The weight of this answer. The final quiz grade will calculated by taking the sum of the user's selected answers divided by the sum of the top weighted answer in each question.",
      },
    },
    {
      type: "RelationshipProperty",
      opts: {
        name: "quiz_question",
        label: "Question",
        relatedModel: "QuizQuestion",
        foreignProperty: "quiz_answer",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: "NumberField", opts: { property: "id" } },
    { type: "DateTimeField", opts: { property: "deleted_at" } },
    {
      type: "StringField",
      opts: { property: "answer", isPrimaryLabelField: true },
    },
    {
      type: "NumberField",
      opts: { property: "weight" },
    },
    {
      type: "RelationshipField",
      opts: {
        property: "quiz_question",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["quiz_question", "answer", "weight"],
      enableSearch: true,
      useModal: true,
      enableDynamicFilters: true,
      inlineEdit: true,
      dynamicFilterOptions: {
        filterFields: ["answer", "weight"],
      },
      defaultSort: [
        {
          field: "answer",
          order: "asc",
        },
      ],
    },
  ];
}
