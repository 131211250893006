import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class License extends IppyDippyCrudModel {
  protected static _typeLabel = "License";
  protected static _typeLabelPlural = "Licenses";
  protected static asProperty = "licenses";
  public static api = {
    path: "licenses",
    fullCache: true,
  };
  protected static routeBase = "licenses";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected userIdField = [];

  protected static propertyDefinitions = [
    {
      type: "NumberProperty",
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "name",
        label: "Name",
        rules: ["required"],
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: "DateProperty",
      opts: {
        name: "start_date",
        label: "Start Date",
      },
    },
    {
      type: "DateProperty",
      opts: {
        name: "end_date",
        label: "End Date",
      },
    },
    {
      type: "RelationshipProperty",
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: "NumberField", opts: { property: "id" } },
    {
      type: "DateTimeField",
      opts: { property: "created_at" },
    },
    { type: "DateTimeField", opts: { property: "updated_at" } },
    { type: "DateTimeField", opts: { property: "deleted_at" } },
    {
      type: "StringField",
      opts: { property: "title", isPrimaryLabelField: true },
    },
    {
      type: "StringField",
      opts: { property: "description" },
    },
    {
      type: "DateField",
      opts: { property: "start_date" },
    },
    {
      type: "DateField",
      opts: { property: "end_date" },
    },
    {
      type: "RelationshipField",
      opts: { property: "user" },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: ["label"],
      enableSearch: true,
      enableDynamicFilters: true,
      useModal: true,
      dynamicFilterOptions: {
        filterFields: [
          "label",
          "description",
          "start_date",
          "end_date",
          "user",
        ],
      },
      defaultSort: [
        {
          field: "label",
          order: "asc",
        },
      ],
    },
  ];
}
