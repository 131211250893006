import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { DateTimeField } from "../plugins/Cruxtify/field-types/DateTimeField";
import { NumberField } from "../plugins/Cruxtify/field-types/NumberField";
import { RelationshipField } from "../plugins/Cruxtify/field-types/RelationshipField";

import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
} from "../plugins/Cruxtify/property-types";
import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class ContentEngagement extends IppyDippyCrudModel {
  protected static _typeLabel = "Content Engagement";
  protected static asProperty = "content_engagement";
  public static api = {
    path: "content-engagements",
  };
  protected static routeBase = "content-engagements";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = [];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Content Saved At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "quiz_taken_at",
        displayRelative: true,
        label: "Quiz Last Taken At",
        computed: true,
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "quiz_score",
        label: "Quiz Score",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "quiz_attempts",
        label: "Quiz Attempts",
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "content",
        label: "Content",
        relatedModel: "Content",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
        foreignProperty: "content_engagements",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    { type: DateTimeField, opts: { property: "quiz_taken_at" } },
    { type: NumberField, opts: { property: "quiz_attempts" } },
    { type: NumberField, opts: { property: "quiz_score" } },

    {
      type: RelationshipField,
      opts: { property: "content" },
    },
    {
      type: RelationshipField,
      opts: { property: "user" },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: [
        "content",
        "user",
        "created_at",
        "quiz_attempts",
        "quiz_score",
      ],
      dynamicFilterOptions: {
        filterFields: [
          "content",
          "user",
          "created_at",
          "quiz_attempts",
          "quiz_score",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
