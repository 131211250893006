import {
  DateTimeField,
  ImageField,
  LongTextField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
  StringField,
} from "../plugins/Cruxtify/field-types";
import { CollectionLayout } from "../plugins/Cruxtify/layouts";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

import {
  DateTimeProperty,
  ImageProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class Content extends IppyDippyCrudModel {
  protected static _typeLabel = "Content";
  protected static _typeLabelPlural = "Content";
  protected static asProperty = "content";
  public static api = {
    path: "contents",
  };
  protected static routeBase = "contents";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = ["curator"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "title",
        label: "Title",
        rules: ["required"],
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "media_link",
        label: "Media Link",
        rules: ["required"],
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "duration",
        label: "Duration",
        description: "(in minutes)",
      },
    },
    {
      type: ImageProperty,
      opts: {
        name: "image_s3_id",
        label: "Image",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "creator",
        label: "Creator",
        relatedModel: "Creator",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "curator",
        label: "Curator",
        relatedModel: "User",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "media_type",
        label: "Media Type",
        relatedModel: "MediaType",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "classroom_levels",
        label: "Classroom Level(s)",
        relatedModel: "ClassroomLevel",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "num_quiz_questions",
        label: "Number of Quiz Questions",
        description:
          "This is the number of quize questions that will be chosen at random for a User to answer.",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "quiz_questions",
        label: "Quiz Questions",
        relatedModel: "QuizQuestion",
        foreignProperty: "content",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "content_categories",
        label: "Categories",
        relatedModel: "ContentCategory",
        foreignProperty: "contents",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    {
      type: StringField,
      opts: { property: "title", isPrimaryLabelField: true, cols: 12 },
    },
    {
      type: LongTextField,
      opts: { property: "description" },
    },
    {
      type: StringField,
      opts: { property: "media_link", cols: 12 },
    },
    {
      type: NumberField,
      opts: { property: "duration", md: 6 },
    },
    {
      type: ImageField,
      opts: { property: "image_s3_id", cols: 12 },
    },
    {
      type: RelationshipField,
      opts: { property: "creator", md: 6 },
    },
    {
      type: RelationshipField,
      opts: { property: "curator" },
    },
    {
      type: RelationshipField,
      opts: { property: "media_type", md: 6 },
    },
    {
      type: RelationshipFieldMany,
      opts: { property: "classroom_levels", md: 6 },
    },
    {
      type: NumberField,
      opts: { property: "num_quiz_questions" },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "quiz_questions",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "content_categories",
      },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: [
            "title",
            "classroom_levels",
            "content_categories",
            "duration",
            "description",
          ],
        },
        {
          id: "media",
          fields: ["image_s3_id", "media_link", "media_type", "creator"],
        },
        {
          id: "quiz",
          fields: ["num_quiz_questions", "quiz_questions"],
        },
        {
          id: "meta",
          fields: ["curator", "created_at", "updated_at", "id"],
        },
      ],
    },
  ];

  protected static collectionLayoutDefinitions = [
    // {
    //   id: "table",
    //   useModal: true,
    //   columnFields: [
    //     "title",
    //     "creator",
    //     "content_categories",
    //     "classroom_levels",
    //     "media_type",
    //   ],
    //   enableSearch: true,
    //   enableDynamicFilters: true,
    //   dynamicFilterOptions: {
    //     filterFields: [
    //       "title",
    //       "creator",
    //       "classroom_levels",
    //       "content_categories",
    //       "media_type",
    //       "curator",
    //       "description",
    //       "quiz_questions",
    //       "media_link",
    //     ],
    //   },
    //   defaultSort: [
    //     {
    //       field: "created_at",
    //       order: "desc",
    //     },
    //   ],
    // },
    {
      type: CollectionLayout,
      opts: {
        componentName: "content-browser",
        id: "card",
        enableSearch: true,
        defaultSort: [
          {
            field: "created_at",
            order: "desc",
          },
        ],
      },
    },
  ];

  public getQuizLink() {
    return "/quiz/" + this.id;
  }

  public async buildQuiz() {
    return this.$nuxt.$api.POST("contents/" + this.id + "/build-quiz");
  }

  public async submitQuiz(questionData, answerData) {
    const quizData = {
      questions: questionData.map((question) => ({
        id: question.id,
        answers: question.answers.map((answer) => answer.id),
      })),
      answers: Object.keys(answerData).map((key) => {
        return {
          question_id: key,
          answer_id: answerData[key],
        };
      }),
    };
    return this.$nuxt.$api.POST(
      "contents/" + this.id + "/submit-quiz",
      quizData
    );
  }
}
