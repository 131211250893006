import {
  CollectionLayout,
  CollectionLayoutDefinition,
  CollectionLayoutOpts,
} from "../CollectionLayout";

export interface ImageGridLayoutDefinition extends CollectionLayoutDefinition {
  dense?: boolean;
  itemClassProperties?: string[];

  imageProperty?: string;
  thumbnailProperty?: string;
  titleProperty?: string;
  subTitleProperty?: string;
  showInfoOnHover?: boolean;
}

export interface ImageGridLayoutOpts
  extends CollectionLayoutOpts,
    ImageGridLayoutDefinition {}

export class ImageGridLayout extends CollectionLayout {
  public static componentName = "image-grid-layout";

  public dense;
  public itemClassProperties: string[] = [];

  public imageProperty: string = "";
  public thumbnailProperty: string = "";
  public titleProperty: string = "";
  public subTitleProperty: string = "";
  public showInfoOnHover: boolean = false;

  constructor(opts: ImageGridLayoutOpts) {
    super(opts);

    if (typeof opts.dense !== "undefined") this.dense = opts.dense;
    else this.dense = this.$nuxt.$config.denseTables;

    if (typeof opts.itemClassProperties !== "undefined")
      this.itemClassProperties = opts.itemClassProperties;

    if (typeof opts.imageProperty !== "undefined")
      this.imageProperty = opts.imageProperty;

    if (typeof opts.thumbnailProperty !== "undefined")
      this.thumbnailProperty = opts.thumbnailProperty;

    if (typeof opts.titleProperty !== "undefined")
      this.titleProperty = opts.titleProperty;

    if (typeof opts.subTitleProperty !== "undefined")
      this.subTitleProperty = opts.subTitleProperty;

    if (typeof opts.showInfoOnHover !== "undefined")
      this.showInfoOnHover = opts.showInfoOnHover;
  }
}
